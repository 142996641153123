import { Button, Col, Row } from 'antd'

import { PropTypes } from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const AcceptCookiesContainer = styled.div`    
    align-items: center;  
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 110;
    position: fixed;  

    background: rgba(15, 15, 15, 0.7);
    box-shadow: 0px 4px 65px rgba(0, 0, 0, 0.05);

    .row-container{
      position: relative;
      display: flex;
      flex-direction: row;
      padding: 24px 20px 24px 90px ;
      justify-content: center;

      ${(props) => props.theme.media['xs']} {      
        flex-direction: column;
        padding: 10px 16px;
      }  
    }

    .text-container {  
      justify-content: center;
      align-items: center; 
      ${(props) => props.theme.media['xs']} {        
        padding: 8px 0px 20px;
      }
    }
`

const Text = styled.div`
    font-style: normal;
    font-weight: 380;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;

    ${(props) => props.theme.media['xs']} {
      margin: 0 0;
      font-family: Open Sans;
      font-size: 12px;
      line-height: 16px;
    }

    .bold { 
        color: #FFFFFF;
        text-decoration: underline;
    }
`
const ButtonContainer = styled.div`
    display: flex;
    align-items: center; 
    justify-content: center;
`

const TextButton = styled.div`
    text-align: center;
    justify-content: center;
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
`

const AcceptCookies = ({ setVisible, setAllowCookie }) => {
  const allowCookie = () => {
    setAllowCookie(true)
    setVisible(false)
  }

  return (
    <AcceptCookiesContainer>
      <Row className='row-container'>
        <Col md={{ span: 19 }} xs={{ span: 24 }} className='text-container'>
          <Text>
            <b>Notice:</b> Block Tree uses cookies to provide necessary website functionality, improve your experience and analyse our traffic. By using our website, you agree to our <a href='https://www.fusang.co/terms-and-conditions' target='_blank' rel='noreferrer'><b className='bold'>Terms & Conditions</b></a> and our <a href='https://www.fusang.co/cookies-policy' target='_blank' rel='noreferrer'><b className='bold'>Cookies Policy</b></a>.
          </Text>
        </Col>
        <Col md={{ span: 5 }} xs={{ span: 24 }}>
          <ButtonContainer>
            <Button
              block
              type='primary'
              htmlType='submit'
              onClick={allowCookie}
              style={{
                backgroundColor: '#FFFFFF',
                width: '123px',
                height: '40px',
                borderRadius: '10px'
              }}>
              <TextButton>ACCEPT</TextButton>
            </Button>
          </ButtonContainer>
        </Col>
      </Row>
    </AcceptCookiesContainer>

  )
}
AcceptCookies.defaultProps = {
  setVisible: null,
  setAllowCookie: null
}

AcceptCookies.propTypes = {
  setVisible: PropTypes.func,
  setAllowCookie: PropTypes.func
}
export default AcceptCookies
