import React from 'react'
import { Typography } from 'antd'

const TermsAndPolicies = () => {
  return <Typography.Link
    href='https://blocktree.co/legal'
    target='_blank'
  >
    Terms and Policies
  </Typography.Link>
}

export default TermsAndPolicies
