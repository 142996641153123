import {
  AuthenticationLayoutContainer,
  IframeLayoutContainer
} from './styled'
import React, { useEffect, useRef, useState } from 'react'

import AcceptCookies from '../../components/AcceptCookies'
import Cookies from 'universal-cookie'
import { getDeployment } from '../../utils/api-call'
import psl from 'psl'

const cookies = new Cookies()
const url = window.location.hostname.toString()
const getDomain = (url) => {
  return psl.parse(url).domain
}

const AuthenticationLayout = (props) => {
  const containerRef = useRef()
  const [visible, setVisible] = useState(false)
  const [allowCookie, setAllowCookie] = useState(undefined)
  const [isActiveScroll, setActiveScrollHeader] = useState()
  const { children } = props

  function onScrollHandler() {
    if (containerRef.current?.scrollTop > 0) {
      setActiveScrollHeader(true)
    } else {
      setActiveScrollHeader(false)
    }
  }

  useEffect(() => {
    const allow = cookies.get(`${getDeployment()}-acceptedCookiePolicy`)
    if (!cookies || allow === undefined) {
      setVisible(true)
    }
  }, [])

  useEffect(() => {
    if (allowCookie) {
      cookies.set(`${getDeployment()}-acceptedCookiePolicy`, 'true', { path: '/', domain: `.${getDomain(url)}` })
    }
  }, [allowCookie])

  return (
    <AuthenticationLayoutContainer
      ref={containerRef}
      onScroll={onScrollHandler}
      className={isActiveScroll && 'container-scrolled'}
    >
      <div className='content-wrapper'>
        {children}
      </div>
      <div className='footer'>
        {visible && <AcceptCookies setAllowCookie={setAllowCookie} setVisible={setVisible} />}
        <div className='branch'>{`© 2015 - ${new Date().getFullYear()} Block Tree`}</div>
      </div>
    </AuthenticationLayoutContainer>
  )
}

function CenterContent({ children, className, alwayBalance, ...rest }) {
  return <div
    className={alwayBalance ? 'container-center-wrapper alway-balance' : 'container-center-wrapper balance'}
    onScroll={() => { console.log('SCROLLING') }}
  >
    <div className={['container-center', className].join(' ')} {...rest}>
      {children}
    </div>
  </div>
}

function FullContent({ children, ...rest }) {
  return <div className='container-full' {...rest}>
    {children}
  </div>
}

const IframeLayout = (props) => {
  const { children } = props

  return (
    <IframeLayoutContainer>
      <div className='content'>{children}</div>
    </IframeLayoutContainer>
  )
}

export { AuthenticationLayout, IframeLayout, CenterContent, FullContent }
