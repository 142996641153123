import styled from 'styled-components'

export const MFARequiredContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .container-center-wrapper {
    max-width: 694px !important;

    .title {
      text-align: center;
      margin-bottom: 40px;
    }

    .title + .hint {
      text-align: center;
      margin-bottom: 30px;
    }
  }
`
