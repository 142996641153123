export const VERIFY_CREDENTIAL_REQUEST = 'VERIFY_CREDENTIAL_REQUEST'
export const VERIFY_CREDENTIAL_SUCCESS = 'VERIFY_CREDENTIAL_SUCCESS'
export const VERIFY_CREDENTIAL_FAILURE = 'VERIFY_CREDENTIAL_FAILURE'

export const CONFIRM_MFA_CODE_REQUEST = 'CONFIRM_MFA_CODE_REQUEST'
export const CONFIRM_MFA_CODE_SUCCESS = 'CONFIRM_MFA_CODE_SUCCESS'
export const CONFIRM_MFA_CODE_FAILURE = 'CONFIRM_MFA_CODE_FAILURE'

export const SETUP_MFA_REQUEST = 'SETUP_MFA_REQUEST'
export const SETUP_MFA_SUCCESS = 'SETUP_MFA_SUCCESS'
export const SETUP_MFA_FAILURE = 'SETUP_MFA_FAILURE'

export const NEW_PASSWORD_REQUIRED_REQUEST = 'NEW_PASSWORD_REQUIRED_REQUEST'
export const NEW_PASSWORD_REQUIRED_SUCCESS = 'NEW_PASSWORD_REQUIRED_SUCCESS'
export const NEW_PASSWORD_REQUIRED_FAILURE = 'NEW_PASSWORD_REQUIRED_FAILURE'

export const SET_STEP = 'SET_STEP'
export const setStep = (step) => ({
  type: SET_STEP,
  payload: { step }
})

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE'

export const LOGOUT_ACTION = 'LOGOUT_ACTION'

export const UPDATE_IFRAME_STATE = 'UPDATE_IFRAME_STATE'

export const VERIFY_ALTERNATIVE_EMAIL_REQUEST =
  'VERIFY_ALTERNATIVE_EMAIL_REQUEST'
export const VERIFY_ALTERNATIVE_EMAIL_SUCCESS =
  'VERIFY_ALTERNATIVE_EMAIL_SUCCESS'
export const VERIFY_ALTERNATIVE_EMAIL_FAILURE =
  'VERIFY_ALTERNATIVE_EMAIL_FAILURE'

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE'

export const VERIFY_MFA_CODE_REQUEST = 'VERIFY_MFA_CODE_REQUEST'
export const VERIFY_MFA_CODE_SUCCESS = 'VERIFY_MFA_CODE_SUCCESS'
export const VERIFY_MFA_CODE_FAILURE = 'VERIFY_MFA_CODE_FAILURE'
