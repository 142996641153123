/* eslint-disable react/display-name */
import { Button, Form, Space, Typography } from 'antd'
import React from 'react'
import { MFA_REQUIRE_HINT } from '@fusang/sso-client'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom'
import { FormContainer } from '../../../../components'
import FusangHeader from '../../../../components/Header'
import MaterialInput from '../../../../components/material-input'
import { HELPER_MESSAGE } from '../../../../constants/message.constant'
import { CenterContent } from '../../../../layout/authentication-layout'
import { HOME_PATH } from '../../../../routers/route.constant'
import { useIsFirstRender } from '../../../../utils/custom-hooks'
import { verifyMFACode } from '../../actions'
import { MFAContainer } from '../mfa-setup/styled'
import { MFARequiredContainer } from './styled'

const { Title } = Typography

const MESSAGE_MAKER = {
  [MFA_REQUIRE_HINT.SESSION_TIMEOUT_EXCEEDED]: (appName) => (<span>
    Your session is timed out. Please enter your 2FA code to go back to <strong>{appName}</strong>.
  </span>),
  [MFA_REQUIRE_HINT.INACTIVITY_TIMEOUT_EXCEEDED]: (appName) => (<span>
    Your authentication is timed out due to inactivity. Please enter your 2FA code to go back to <strong>{appName}</strong>.
  </span>)
}

function splitInto2Part(str, separator) {
  const separatorIndex = str.indexOf(separator)
  return [str.slice(0, separatorIndex), str.slice(separatorIndex + 1)]
}
function parseURLParams(url) {
  if (!url) return {}

  const [firstParam, ...paramParts] = url?.split('&')
  const [origin_path, firstParamString] = splitInto2Part(firstParam, '?')
  return [firstParamString, ...paramParts].reduce((result, current) => {
    const [key, value] = splitInto2Part(current, '=')
    return { ...result, [key]: value }
  }, {})
}

const useLocationWithHash = () => {
  const { search, hash, ...rest } = useLocation()

  if (hash) {
    const _search = parseURLParams(window.location.href)
    return { search: _search, hash, ...rest }
  }

  return { search, hash, ...rest }
}

const MFARequired = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { isVerifyingMfaCode } = useSelector((state) => state.auth)
  const isFirstRender = useIsFirstRender()
  const { search } = useLocationWithHash()
  const { applications: allApps } = useSelector((state) => state.application)
  const urlParams = new URLSearchParams(search)
  const redirectUri = urlParams.get('redirectUri')
  const clientId = urlParams.get('clientId')
  const hint = urlParams.get('hint')

  console.log('PARAMS', redirectUri, clientId, hint, MFA_REQUIRE_HINT)

  const confirmCode = async ({ code }) => {
    dispatch(verifyMFACode({
      code,
      redirectUri,
      clientId: clientId
    }))
  }
  const currentApp = allApps.find((app) => app.key === clientId)

  return (
    <>
      <MFARequiredContainer>
        <FusangHeader showCloseBtn={false} />
        <CenterContent>
          <MFAContainer>
            <Title level={5} className='title'>
              Enter your 2 Factor Authentication
            </Title>
            <div className='hint'>
              {(MESSAGE_MAKER[hint] && currentApp) && MESSAGE_MAKER[hint](currentApp.fullName)}
            </div>
            <FormContainer form={form} name='mfaToken' onFinish={confirmCode}>
              <Form.Item
                name='code'
                label='2FA Code'
                rules={[
                  { required: true },
                  { pattern: '\\d{6}', message: HELPER_MESSAGE.MFA_CODE, len: 6 }
                ]}
              >
                <MaterialInput
                  autoFocus
                  disallowSpace
                  label='2FA CODE (6 DIGITS)'
                  loading={isVerifyingMfaCode}
                  required
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    block
                    htmlType='submit'
                    loading={isVerifyingMfaCode}
                    size='large'
                    type='primary'
                    disabled={
                      isFirstRender.value || !form.isFieldsTouched(true) || isVerifyingMfaCode ||
                      form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                    }
                  >Submit</Button>
                )}
              </Form.Item>
              <Space align={'end'} direction={'vertical'} style={{ width: '100%' }}>
                <RouterLink to={HOME_PATH}>
                  View Dashboard
                </RouterLink>
              </Space>
            </FormContainer>
          </MFAContainer>
        </CenterContent>
      </MFARequiredContainer>
    </>
  )
}

export default MFARequired
